$black: #0F0A10;
$salt-pan: #F5FAF5;
$cashmere: #E6A7A0;
$contessa: #C16774;
$contessa-dark: #AE4753;
$pale-oyster: #9D8C78;
$bleached-cedar: #2D1C31;

$text-dark: $black;
$text-light: $salt-pan;

$background-dark: $black;
$background-light: $salt-pan;

$primary: $contessa;
$primary-dark: $contessa-dark;

$secondary: $bleached-cedar;

$tertiary: $pale-oyster;