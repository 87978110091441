.post {
  &.content {
    column-gap: 0.5rem;

    .content__header {
      margin-bottom: $space-s;
      padding: $space-m-3xl 0;
      text-align: left;

      &__title {
        font-size: $font-size-step-5;
        line-height: $leading-none;
      }

      &__desc {
        margin-top: $space-s;
        font-size: $font-size-step-1;
        line-height: $leading-tight;
      }
    }

    .content__toc, .content__footer {
      grid-column: span 12 / span 12;
      grid-column-start: 0;
    }

    .content__toc {
      width: 100%;
      margin: $space-s auto;

      &__content {
        width: 100%;
        padding: $space-xs $space-s;
        border: solid 0.125em $background-dark;
        box-shadow: 0.25em 0.25em $background-dark;

        &__title {
          padding: $space-xs 0 $space-s 0;
          font-size: $font-size-step-2;
        }

        & > nav {
          & > ul {
            padding: 0;
            margin: 0;
            font-size: $font-size-step--1;
            list-style: none;

            li {
              margin-bottom: $space-3xs;
            }
          }
        }
      }
    }

    .content__footer {
      &__tags, &__categories {
        &__label {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .post {
    &.content {
      .content__header, .content__footer {
        grid-column: span 10 / span 10;
        grid-column-start: 2;
      }

      .content__toc {
        grid-column: span 3 / span 3;
        grid-column-start: 9;
        margin: 0;
        max-width: 415px;
      }

      .content__body {
        grid-column: span 7 / span 7;
        grid-column-start: 2;
        grid-row-start: 2;
        margin: 0;
      }
    }
  }
}