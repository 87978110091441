.content {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: $space-xs-s $space-2xs-xs;

  &__header,
  &__body {
    grid-column: span 12 / span 12;
  }

  &__header {
    padding: $space-m-xl 0;
    text-align: center;

    &__title {
      font-size: $font-size-step-6;
    }

    &__pub {
      margin-top: $space-2xs-xs;
      font-size: $font-size-step--2;

      &__update {
        padding: $space-3xs $space-s;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: $tracking-wide;
        background: $background-dark;
        color: $text-light;
        border-radius: 15px;
      }
    }
  }

  &__body {
    max-width: 100%;
    margin: 0 auto;

    > * {
      margin-bottom: 1rem;
    }
  }

  &__footer {
    padding: $space-m-l 0;

    &__pagination {
      margin-top: $space-m-l;

      &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        &__prev,
        &__next {
          > a {
            display: block;
            position: relative;
            width: 100%;
            max-width: 720px;
            padding: $space-xs $space-s;
            border-color: $background-dark;
            background: $background-dark;
            color: $text-light;
            transition-property: color, background-color;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;

            &:hover {
              background: $background-light;
              color: $text-dark;
            }
          }
        }

        &__prev {
          margin-bottom: $space-xs-s;

          > a {
            text-align: right;

            &::before {
              content: "\00AB";
              position: absolute;
              left: $space-s;
            }
          }
        }

        &__next {
          > a {
            &::after {
              content: "\00BB";
              position: absolute;
              right: $space-s;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .content {
    &__header,
    &__body,
    &__footer {
      grid-column: 3 / 11;
    }

    &__body {
      max-width: $content-max-width;
    }

    &__footer {
      &__pagination {
        &__list {
          &__prev,
          &__next {
            > a {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .content {
    &__body,
    &__header,
    &__footer {
      grid-column: 4 / 10;
    }

    &__footer {
      &__pagination {
        &__list {
          &__prev,
          &__next {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
