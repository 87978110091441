/* @link https://utopia.fyi/space/calculator?c=320,21,1.2,1140,24,1.25,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,m-3xl|m-xl */
$space-3xs: clamp(0.31rem, calc(0.29rem + 0.12vw), 0.38rem);
$space-2xs: clamp(0.69rem, calc(0.66rem + 0.12vw), 0.75rem);
$space-xs: clamp(1.00rem, calc(0.95rem + 0.24vw), 1.13rem);
$space-s: clamp(1.31rem, calc(1.24rem + 0.37vw), 1.50rem);
$space-m: clamp(2.00rem, calc(1.90rem + 0.49vw), 2.25rem);
$space-l: clamp(2.63rem, calc(2.48rem + 0.73vw), 3.00rem);
$space-xl: clamp(3.94rem, calc(3.72rem + 1.10vw), 4.50rem);
$space-2xl: clamp(5.25rem, calc(4.96rem + 1.46vw), 6.00rem);
$space-3xl: clamp(7.88rem, calc(7.44rem + 2.20vw), 9.00rem);

/* One-up pairs */
$space-3xs-2xs: clamp(0.31rem, calc(0.14rem + 0.85vw), 0.75rem);
$space-2xs-xs: clamp(0.69rem, calc(0.52rem + 0.85vw), 1.13rem);
$space-xs-s: clamp(1.00rem, calc(0.80rem + 0.98vw), 1.50rem);
$space-s-m: clamp(1.31rem, calc(0.95rem + 1.83vw), 2.25rem);
$space-m-l: clamp(2.00rem, calc(1.61rem + 1.95vw), 3.00rem);
$space-l-xl: clamp(2.63rem, calc(1.89rem + 3.66vw), 4.50rem);
$space-xl-2xl: clamp(3.94rem, calc(3.13rem + 4.02vw), 6.00rem);
$space-2xl-3xl: clamp(5.25rem, calc(3.79rem + 7.32vw), 9.00rem);

/* Custom pairs */
$space-m-xl: clamp(2.00rem, calc(1.02rem + 4.88vw), 4.50rem);
$space-m-3xl: clamp(2.00rem, calc(-0.73rem + 13.66vw), 9.00rem);

/* Content Widths */
$shell-max-width: 1200px;
$content-max-width: 70ch;
