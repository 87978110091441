.til {
  &.content {
    .content__header {
      margin-bottom: $space-s;
      padding: $space-m-3xl 0;
      text-align: left;

      &__title {
        font-size: $font-size-step-5;
        line-height: $leading-none;
      }

      &__topic {
        margin-top: $space-2xs-xs;
        font-weight: 500;
      }

      &__pub {
        margin-top: $space-3xs;
      }
    }
  }
}