table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;

  thead {
    border-bottom: 3px solid;
  }

  tr {
    th,
    td {
      border: 1px solid;
      border-color: transparent;
      padding: $space-2xs;
    }
  }
}
