.list {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: $space-xs-s $space-2xs-xs;

  &__header {
    grid-column: span 12 / span 12;
    padding: $space-m-xl 0;
    text-align: center;

    &__title {
      font-size: $font-size-step-6;
    }
  }

  &__content, &__pages {
    grid-column: span 12 / span 12;
    margin: 0 auto;
  }

  &__content {
    max-width: $content-max-width;

    > * {
      margin-bottom: 1rem;
    }
  }

  &__pages {
    &__items {
      padding: 0;
      list-style: none;

      &__item {
        padding: $space-m 0;

        &__title {
          font-size: $font-size-step-3;

          > a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &__desc {
          margin-top: $space-3xs;
          font-size: $font-size-step-0;
          font-weight: 500;
        }

        &__pub {
          margin-top: $space-3xs;
          font-size: $font-size-step--2;

          &__update {
            padding: $space-3xs $space-2xs;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: $tracking-wide;
            background: $background-dark;
            color: $text-light;
            border-radius: 15px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .list {
    &__header, &__content, &__pages {
      grid-column: 3 / 11;
    }
  } 
}

@media screen and (min-width: 1280px) {
  .list {
    &__content, &__pages {
      grid-column: 4 / 10;
    }
  } 
}