/* Font Faces */
@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/inter/Inter.var.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/inter/Inter-italic.var.woff2') format('woff2');
}

@font-face {
  font-family: 'Commit Mono';
  font-weight: 100 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/commit-mono/CommitMono-Variable.woff2') format('woff2');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-Light.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-Light.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-Regular.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-Medium.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-SemiBold.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-Bold.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-LightItalic.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-Italic.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-MediumItalic.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-SemiBoldItalic.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Cormorant Garamond';
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url('/fonts/cormorant-garamond/CormorantGaramond-BoldItalic.woff2') format('woff2'),
       url('/fonts/cormorant-garamond/CormorantGaramond-BoldItalic.woff') format('woff');
}

/* Font Stacks */
$font-serif: "Cormorant Garamond", ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
$font-sans: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-mono: "Commit Mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; 

/* Font Sizes */
/* @link https://utopia.fyi/type/calculator?c=320,21,1.2,1140,24,1.25,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */
$font-size-step--4: clamp(0.61rem, calc(0.64rem + -0.04vw), 0.63rem);
$font-size-step--3: clamp(0.77rem, calc(0.76rem + 0.02vw), 0.76rem);
$font-size-step--2: clamp(0.96rem, calc(0.89rem + 0.10vw), 0.91rem);
$font-size-step--1: clamp(1.20rem, calc(1.05rem + 0.21vw), 1.09rem);
$font-size-step-0: clamp(1.31rem, calc(1.24rem + 0.37vw), 1.50rem);
$font-size-step-1: clamp(1.58rem, calc(1.46rem + 0.59vw), 1.88rem);
$font-size-step-2: clamp(1.89rem, calc(1.71rem + 0.89vw), 2.34rem);
$font-size-step-3: clamp(2.27rem, calc(2.01rem + 1.29vw), 2.93rem);
$font-size-step-4: clamp(2.72rem, calc(2.36rem + 1.83vw), 3.66rem);
$font-size-step-5: clamp(3.27rem, calc(2.75rem + 2.56vw), 4.58rem);
$font-size-step-6: clamp(3.92rem, calc(3.22rem + 3.52vw), 5.72rem);

/* Letter Spacing */
$tracking-tight: -0.025em;
$tracking-wide: 0.025em;

/* Line Height */
$leading-none: 1;
$leading-tight: 1.25;
$leading-normal: 1.5;
$leading-relaxed: 1.625;
