.site-footer {
  margin: 0 auto;
  padding: $space-xs-s $space-2xs-xs;
  max-width: $shell-max-width;

  &__social {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: $space-2xs;

    li {
      margin: 0 $space-2xs;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__link {
      display: block;
      text-decoration: none;
      color: $text-dark;
      transition-property: color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;

      &:hover {
        color: $primary-dark;
      }
    }
  }

  &__bottom {
    text-align: center;

    p {
      font-size: $font-size-step--2;
    }

    &__to-top {
      margin-top: $space-2xs;
    }
  }
}

@media screen and (min-width: 780px) {
  .site-footer {
    &__bottom {
      display:flex;
      align-items: center;
      justify-content: space-between;
      margin-top: $space-m;

      &__to-top {
        margin-top: unset;
      }
    }
  }
}
