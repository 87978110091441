.site-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: $space-xs-s $space-2xs-xs;
  max-width: $shell-max-width;

  &__brand {
    display: block;
    width: 12rem;
  }

  &__nav {
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0;
      padding: $space-xs 0;
      font-size: $font-size-step--2;
      list-style: none;

      li {
        margin: 0 $space-2xs;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.025em;
          color: $text-dark;

          &:hover {
            text-decoration: underline;
          }

          &[aria-current="Page"] {
            color: $primary-dark;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 780px) {
  .site-head {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .site-head {
    &__brand {
      width: 14rem;
    }
  }
}
