h1, h2, h3, h4, h5, h6 {
  font-family: $font-serif;
  font-weight: 600;
  letter-spacing: $tracking-tight;
  line-height: $leading-tight;
}

h1 {
  font-size: $font-size-step-5;
}

h2 {
  font-size: $font-size-step-4;
}

h3 {
  font-size: $font-size-step-3;
}

h4 {
  font-size: $font-size-step-2;
}

h5 {
  font-size: $font-size-step-1;
}

h6 {
  font-size: $font-size-step-0;
}
